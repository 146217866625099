@import '../../styles/gridDefaults.scss';

.col12 {
    margin-bottom: 10px;
}

.dividerLine {
    border: 1px solid var(--matterColorNegative);
    margin-top: 15px;
    margin-bottom: 15px;
}

.priceBreakdownContainer {
    width: 100%;
}

.customOfferNotAvailable {
    color: var(--attentionColor);
}

.customOfferAccepted {
    color: var(--successColor);
}

.withdrawalOffer {
    float: right;
    border-radius: 6px;
    background-color: var(--attentionColor);

    &:hover {
        cursor: pointer;
    }
}

.error {
  color: var(--failColor);
}
